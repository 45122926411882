<template>
  <div class="container my-24 my-lg-40">
    <div class="row">
      <div class="col-12 col-xl-3 order-xl-2 mb-24 sticky">
        <v-card class="overflow-hidden sticky">
          <l-widget>
            <template #header>
              <w-timer
                :start="widget.timer.start"
                :end="widget.timer.end"
                :server-time="widget.timer.current"
              />
            </template>

            <template #default>
              <w-progress
                :progress="widget.progress"
              />
            </template>
          </l-widget>
        </v-card>
      </div>

      <div class="col-12 col-xl-9 order-xl-1">
        <v-card class="py-32 px-16 p-xl-32">
          <div class="row">
            <div class="col">
              <p>
                Оцените, какое утверждение Вам ближе. Вы можете выбрать только одно более близкое Вам утверждение из
                двух.
              </p>
            </div>
          </div>

          <div class="row mt-8 mt-xl-16">
            <div class="col">
              <linear-choice
                :value="answer"
                :options="variant"
              >
                <template #textLeft>
                  {{ lText }}
                </template>

                <template #default>
                  <v-linear-radio
                    v-model="answer"
                    :variants="variant"
                  ></v-linear-radio>
                </template>

                <template #textRight>
                  {{ rText }}
                </template>
              </linear-choice>
            </div>
          </div>

          <div class="row mt-40">
            <div class="col">
              <v-button @click="onClick">
                Ответить
              </v-button>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { VButton, VCard, VLinearRadio } from '@components/base'
import { LinearChoice } from '@components/features'
import { WProgress, WTimer } from '@components/widgets'
import LWidget from '@/layouts/LWidget'

export default {
  name: 'SLinearChoice',

  components: {
    LWidget,
    LinearChoice,
    VButton,
    VCard,
    VLinearRadio,
    WProgress,
    WTimer
  },

  data () {
    return {
      lText: 'Важно, чтобы сотрудники любили свое дело и верили в миссию команды',
      rText: 'Важно, чтобы сотрудники стремились к рекордам и лостижениям',
      variant: [
        {
          id: '1',
          text: 'Согласен'
        },
        {
          id: '2',
          text: 'Скорее согласен'
        },
        {
          id: '3',
          text: 'Скорее согласен'
        },
        {
          id: '4',
          text: 'Согласен'
        }
      ],
      widget: {
        progress: {
          total: 3,
          current: 1
        },
        timer: {
          start: Math.floor(+new Date() / 1000),
          end: Math.floor(+new Date() / 1000) + 120
        }
      },
      answer: ''
    }
  },

  methods: {
    onClick () {
      console.log('ответ', this.answer)
    }
  }
}
</script>
